
<template>
  <div class="content-body">
    <div class="academic-container">
        <div class="row mg-0">
        <div class="col-sm-5">
            <div class="content-header pd-l-0">
            <div>
                <h4 class="content-title content-title-xs">Manage Employee</h4>
            </div>
            </div>
            <!-- content-header -->
        </div>
        <!-- col -->
        </div>
        <!-- row -->
        <div class="row my-2">
            <div class="filter-input col-md-9 d-flex mr-0 pr-0">
            <select v-model="filter.size">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
            </select>
            </div>
            <div class="col-md-3 ml-0 pl-0">
            <input type="text" v-model="filter.key"  class="float-right" placeholder="Search...">
            </div>
        </div>
        <div class="row py-0">
        <div class="col-md-12 year-transfer-table-body">
            <table class="table table1 table-hover" id="tableData">
            <thead>
                <tr>
                <th class="wd-5p table-start-item">S.N</th>
                <th class="wd-10p">Emp. ID</th>
                <th class="wd-15p">Employee Name</th>
                <th class="wd-10p">Designaton</th>
                <th class="wd-15p">Email</th>
                <th class="wd-10p">Contact No.</th>
                <th class="wd-10p">Advance</th>
                <th class="wd-10p">Salary Due</th>
                <th class="wd-5p">Status</th>
                <th class="wd-10p text-right table-end-item">Action</th>
                </tr>
            </thead>
            <tbody v-for="(employee, index) in dataSets" :key="index">
                <tr v-if="!removeList.includes(employee.id)">
                <th scope="row" class="table-start-item">{{ ++index }}</th>
                <td>{{ employee.employee_id }}</td>
                <td>{{ employee.name }}</td>
                <td>{{ employee.designation }}</td>
                <td>{{ employee.email }}</td>
                <td>{{ employee.mobile_no }}</td>
                <td>{{ employee.advance }}</td>
                <td>{{ employee.closing_due }}</td>
                <td>
                    <div class="tx-primary tx-500" v-if="(employee.status = 1)">
                    Active
                    </div>
                    <div class="tx-danger tx-500" v-else>Deactive</div>
                </td>
                <td class="text-right table-end-item">
                    <a href="javascript:;">
                    <i class="fa fa-trash" @click="drop(employee.id)"></i>
                    </a>
                </td>
                </tr>
            </tbody>
            </table>
            <Pagination />
        </div>
        <div class="col-md-12 text-right mt-3">
            <button type="submit" class="btn btn-sm btn-primary" @click="$router.push(`/academic-year-transfer/payment-method`)">Next</button>
        </div>
      </div>
    </div>
  </div>  
</template>
<script>
import { mapGetters } from "vuex";
import {Search} from "../../../../../../../mixins/search";
import Pagination from "../../../../../components/pagination/pagination";
export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  methods: {
    view(id) {
      this.$router.push(`employee/profile/${id}`);
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/employee/${id}`);
    },
    filterByBranch() {
      if (this.dataLists.length != 0) {
        let branch = this.filter.branch;
        let details = this.dataLists.filter(function (data) {
          return data.branch_id == branch;
        });
        this.dataSets = { ...details };
      }
    },
    getData() {
      this.$store.commit("getData", `api/employee/size/${this.filter.size}`);
    },
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
  },
  mounted() {
    this.getData();
    this.$store.commit("getData2", `api/branch`);
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
  },
  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>
<style scoped>
</style>